<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar color="primary">
        <ion-title>Wiki</ion-title>
      </ion-toolbar>
      <ion-toolbar>
        <ion-searchbar placeholder="Zoeken"
          autocorrect="off"
          enterkeyhint="search"
          v-model="searchTerm"
          debounce="1000"
          ref="search"
          @ionChange="search()" />
      </ion-toolbar>

    </ion-header>

    <ion-content :fullscreen="true">
      <router-link :to="'/wiki/' + result.title"
        v-for="result in results"
        :key="result.pageid">
        <ion-item detail>
          <ion-label text-wrap>
            <h2>{{result.title}}</h2>
            <div v-html="result.snippet.replace('[[', '').replace(']]','')"></div>
          </ion-label>
        </ion-item>
      </router-link>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonSearchbar,
  IonItem,
  IonLabel,
} from "@ionic/vue";
import { defineComponent } from "vue";
import axios from "axios";

export default defineComponent({
  name: "Home",
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonSearchbar,
    IonItem,
    IonLabel,
  },
  data() {
    return {
      searchTerm: this.$route.params.search,
      results: []
    };
  },
  mounted(){
    this.search();
  },
  methods: {
    async search() {
      if(this.searchTerm) {
        const response = await axios.get(
          "https://app-api.kazoulimburg.be/wiki/search/" +
            this.searchTerm
        );
        this.results = response.data;
      } else {
        this.$router.push({name:'Wiki'})
      }
    },
  },
});
</script>

<style>
.searchmatch {
  font-weight: bolder;
}
</style>