<template>
  <ion-page>
    <ion-content class="welcome-page ion-padding">
      <ion-img src="../../assets/img/logo.png" />
      <h1>Welkom!</h1>

      <ion-button @click="openKazouLogin" expand="block">Login met je Kazou-profiel</ion-button>
    </ion-content>
  </ion-page>
</template>

<style scoped>
.welcome-page {
  background: url("../../public/assets/img/welcome-bg.jpeg") no-repeat center
    center fixed;
  background-size: cover;
}
</style>

<script>
import { loadingController, IonButton, IonContent, IonPage, IonImg } from "@ionic/vue";
import { defineComponent } from "vue";
import { Browser } from "@capacitor/browser";

export default defineComponent({
  name: "Welcome",
  components: {
    IonContent,
    IonPage,
    IonButton,
    IonImg,
  },
  data() {
    return {
      user: null,
      error: false,
      notCorrect: false,
      username: null,
      password: null,
    };
  },
  methods: {
    async openKazouLogin() {
      await Browser.open({ url: "https://app-api.kazoulimburg.be/auth/kazoulimburg" });
    },
    async login() {
      const loading = await loadingController.create({
        spinner: "bubbles",
        message: "Inloggen...",
        translucent: true,
        cssClass: "custom-class custom-loading",
        backdropDismiss: true,
        animated: true,
      });
      await loading.present();
      
      loading.dismiss();
    },
  },
});
</script>
