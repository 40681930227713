<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar color="primary">
        <ion-title>Evenementen</ion-title>
      </ion-toolbar>
    </ion-header>

    <contentView :response="response"
      v-if="response"> </contentView>
  </ion-page>
</template>

<script>
import { IonHeader, IonPage, IonTitle, IonToolbar } from "@ionic/vue";
import { defineComponent } from "vue";
import axios from "axios";
import contentView from "./content.vue";

export default defineComponent({
  name: "Home",
  components: {
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    contentView
  },
  data() {
    return {
      response: null
    };
  },
  async mounted() {
    this.response = await axios.get(
      "https://wiki.kazoulimburg.be/api.php?action=parse&page=Evenementen&format=json&origin=*"
    );
  }
});
</script>
