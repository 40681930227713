<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar color="primary">
        <ion-buttons slot="start">
          <ion-back-button></ion-back-button>
        </ion-buttons>
        <ion-title>{{title}}</ion-title>
      </ion-toolbar>
    </ion-header>

    <contentView :response="response"
      :key="title"
      v-if="response"> </contentView>
  </ion-page>
</template>

<script>
import {
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  loadingController,
  IonButtons,
  IonBackButton
} from "@ionic/vue";
import { defineComponent } from "vue";
import axios from "axios";
import contentView from "./content.vue";

export default defineComponent({
  name: "Home",
  components: {
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonBackButton,
    contentView
  },
  data() {
    return {
      response: null,
      title: null
    };
  },
  watch: {
    "key"() {
      this.loadData();
    }
  },
  methods: {
    async loadData() {
      const loading = await loadingController.create({
        spinner: "bubbles",
        message: "laden...",
        translucent: true,
        cssClass: "custom-class custom-loading",
        backdropDismiss: true,
        animated: true
      });
      await loading.present();

      this.response = await axios.get( "https://app-api.kazoulimburg.be/wiki/content/" + this.$route.params.page);
      if (this.response && this.response.data && this.response.data.parse) {
        this.title = this.response.data.parse.displaytitle;
      }
      loading.dismiss();
    }
  },
  async created() {
    this.loadData();
  }
});
</script>