<template>

  <ion-content :fullscreen="true">
    <div v-if="file">
      <img :src="file.thumb.source"
        style="opacity: 0.5" />
      <div style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); -ms-transform: translate(-50%, -50%);">
        <a @click="openLink(file.imageinfo[0].url)"><img src="img/download.png"
            height="100"
            width="100" /></a>
      </div>
    </div>
    <div id="container">
      <v-runtime-template :template="content"
        :parent="this"></v-runtime-template>
    </div>
    <div v-if="refsCat.length > 0">
      <ion-list-header lines="full"
        color="secondary">
        <ion-label>Categorieën</ion-label>
      </ion-list-header>
      <router-link :to="'/wiki/'+ref.title"
        v-for="ref in refsCat"
        :key="ref.pageid">
        <ion-item detail>
          <ion-label>
            {{ref.title.replace('Categorie:', '')}}
          </ion-label>
        </ion-item>
      </router-link>
    </div>

    <div v-if="refsPage.length > 0">
      <ion-list-header lines="full"
        color="secondary">
        <ion-label>Pagina's</ion-label>
      </ion-list-header>
      <router-link :to="'/wiki/'+ref.title"
        v-for="ref in refsPage"
        :key="ref.pageid">
        <ion-item detail>
          <ion-label>
            {{ref.title}}
          </ion-label>
        </ion-item>
      </router-link>
    </div>
    <div v-if="refsFile.length > 0">
      <ion-list-header lines="full"
        color="secondary">
        <ion-label>Bestanden</ion-label>
      </ion-list-header>
      <router-link :to="'/wiki/'+ref.title"
        v-for="ref in refsFile"
        :key="ref.pageid">
        <ion-item detail>
          <ion-label>
            {{ref.title}}
          </ion-label>
        </ion-item>
      </router-link>
    </div>

    <div style="margin: 10px; 15px">
      <router-link :to="'/wiki/Categorie:'+cat"
        v-for="cat in categories"
        :key="cat">
        <ion-chip color="secondary">
          <ion-label>{{cat.replaceAll('_', ' ')}}</ion-label>
        </ion-chip>
      </router-link>
    </div>
  </ion-content>
</template>

<script>
import { IonContent, IonLabel, IonListHeader, IonItem,IonChip } from "@ionic/vue";
import { defineComponent } from "vue";
import axios from "axios";
import sanitizeHtml from "sanitize-html";
import VRuntimeTemplate from "vue3-runtime-template";
import { Browser } from '@capacitor/browser';

export default defineComponent({
  name: "Home",
  components: {
    IonContent,
    IonLabel, 
    IonListHeader, 
    IonItem,
    IonChip,
    VRuntimeTemplate
  },
  props: ["response"],
  data() {
    return {
      content: null,
      title: null,
      categories: [],
      refs: [],
      refsCat: [],
      refsPage: [],
      refsFile: [],
      file: null
    };
  },
  methods: {
    async loadData() {
      if (
        this.response &&
        this.response.data &&
        this.response.data.parse &&
        this.response.data.parse.text &&
        this.response.data.parse.text["*"]
      ) {
        this.title = this.response.data.parse.displaytitle;
        this.categories = this.response.data.parse.categories.map(o => o["*"]);
        const page = this.$route.params.page;
        if (this.response.data.parse.text["*"].includes("redirectMsg")) {
          this.$router.push("/wiki/" + this.response.data.parse.links[0]["*"]);
        }
        this.content = sanitizeHtml(this.response.data.parse.text["*"], {
          allowedTags: false,
          allowedAttributes: false,
          transformTags: {
            div: function(tagName, attribs) {
              if (
                attribs &&
                attribs.class &&
                attribs.class.includes("thumbinner")
              ) {
                attribs.style = "width: 100%";
              }
              return {
                attribs,
                tagName
              };
            },
            a: function(tagName, attribs) {
              let newAttribs = attribs;
              let newTagName = "a";

              if (attribs.href && !attribs.href.includes("http")  && !attribs.href.includes("mailto")  && !attribs.href.includes("tel")) {
                newTagName = "router-link";
                newAttribs = {};
                if (attribs.href.substring(0, 1) === "#") {
                  newTagName = "a";
                  newAttribs['@click'] = "scrollTo('" + attribs.href.substring(1) +"')";
                } else {
                  newAttribs.to = "/wiki/" + attribs.href.substring(1);
                }
              } else if(attribs.href && attribs.href.includes("http")) {
                newTagName = "span";
                newAttribs['class'] = "externalLink";
                newAttribs['@click'] = "openLink('" + attribs.href +"')";
              } else if(attribs.href) {
                newTagName = "a";
                newAttribs['class'] = "externalLink";
                newAttribs['href'] = attribs.href;
              }

              return {
                tagName: newTagName,
                attribs: newAttribs
              };
            },
            img: function(tagName, attribs) {
              delete attribs.width;
              delete attribs.height;
              attribs.style = "width: 100%";

              if (attribs.src && !attribs.src.includes("http")) {
                attribs.src = "https://wiki.kazoulimburg.be" + attribs.src;
              }
              if (attribs.srcset && !attribs.srcset.includes("http")) {
                attribs.srcset = attribs.srcset.replaceAll(
                  "/images/",
                  "https://wiki.kazoulimburg.be/images/"
                );
              }

              return {
                tagName,
                attribs
              };
            },
            iframe: function(tagName, attribs) {
              delete attribs.width;
              delete attribs.height;
              attribs.style = "width: 100%";

              if (attribs.src && attribs.src.startsWith("//")) {
                attribs.src = "https:" + attribs.src;
              }
              return {
                tagName,
                attribs
              };
            }

          }
        });
        console.log(this.content);
      }
      this.refs = [];
      this.refsCat = [];
      this.refsPage = [];
      this.refsFile = [];
      this.file = null;
      if (this.title.includes("Categorie:")) {
        const responseCat = await axios.get(
          "https://wiki.kazoulimburg.be/api.php?action=query&list=categorymembers&format=json&origin=*&cmlimit=500&cmtitle=" +
          encodeURIComponent(this.$route.params.page)
        );
        if (
          responseCat &&
          responseCat.data &&
          responseCat.data.query &&
          responseCat.data.query.categorymembers
        ) {
          this.refs = responseCat.data.query.categorymembers.sort((a, b) =>
            ("" + a.title).localeCompare(b.title)
          );
          this.refsCat = this.refs.filter(o => o.ns === 14);
          this.refsFile = this.refs.filter(o => o.ns === 6);
          this.refsPage = this.refs.filter(o => ![14, 6].includes(o.ns));
        }
      }
      if (this.title.includes("Bestand:")) {
        const responseFile = await axios.get(
          "https://wiki.kazoulimburg.be/api.php?action=query&prop=imageinfo&format=json&origin=*&iiprop=url&titles=" +
          encodeURIComponent(this.$route.params.page)
        );
        if (
          responseFile &&
          responseFile.data &&
          responseFile.data.query &&
          responseFile.data.query.pages &&
          responseFile.data.query.pages[this.response.data.parse.pageid]
        ) {
          this.file =
            responseFile.data.query.pages[this.response.data.parse.pageid];
          console.log(
            responseFile.data.query.pages[this.response.data.parse.pageid]
          );
        }
        const responseFileThumb = await axios.get(
          "https://wiki.kazoulimburg.be/api.php?action=query&prop=pageimages&pithumbsize=1200&format=json&origin=*&iiprop=url&titles=" +
          encodeURIComponent(this.$route.params.page)
        );
        if (
          responseFileThumb &&
          responseFileThumb.data &&
          responseFileThumb.data.query &&
          responseFileThumb.data.query.pages &&
          responseFileThumb.data.query.pages[this.response.data.parse.pageid]
        ) {
          this.file.thumb =
            responseFileThumb.data.query.pages[
              this.response.data.parse.pageid
            ].thumbnail;
        }
      }
    },
    scrollTo(ref){
      const el = document.getElementById(ref);
      const y = el.offsetTop - 70;
      console.log(y);
      window.scrollTo({top: y});
    },
    openLink(link) {
      Browser.open({ url: link});
    }
  },
  async created() {
    this.loadData();
  },
});
</script>

<style>
.mw-parser-output:not(:empty) {
  padding: 15px;
}
.mw-editsection {
  display: none;
}

h2 {
  font-size: 20px;
  background: rgb(246 246 246);
  padding: 15px 20px;
  margin: 0px -15px;
}

@media (prefers-color-scheme: dark) {
  h2 {
    background: rgb(47, 47, 47);
  }
}

h3 {
  color: #00cf97;
}

.thumbcaption {
  font-size: 13px;
}

.infobox {
  width: 100% !important;
  float: inherit !important;
  margin: 0px !important;
}

.infobox td {
  height: 30px;
}

blockquote {
  border-left: 5px solid #00cf97;
  padding-left: 15px;
  margin: 10px;
}

#container a:visited,
#container a:focus,
#container a,
#container a:link {
  color: inherit;
  text-decoration: none;
  border-bottom: 0.7px solid #02cf97;
}

.externalLink {
  color: inherit;
  text-decoration: none;
  border-bottom: 0.7px solid #02cf97;
  }

  .gallery{
    display: flex;
    max-width: 100%;
    flex-wrap: wrap;
    margin: 1rem;
    padding: 0;
  }
  .gallery li {
    width: calc((100vw / 3) - 2rem) !important;
    min-width: calc((100vw / 3) - 2rem) !important;
    list-style: none;
    padding: 40px;
  }

  .gallery li > div {
    width: calc((100vw / 3) - 2rem - 20px) !important;
    min-width: calc((100vw / 3) - 2rem - 20px) !important;
  }

  .gallery li > div > .thumb {
    width: calc((100vw / 3) - 2rem - 20px) !important;
    min-width: calc((100vw / 3) - 2rem - 20px) !important;
  }

  #container .gallery a:visited,  #container .gallery a:focus,  #container .gallery a,  #container .gallery a:link {
    text-decoration: none;
    border-bottom: none;
  }
  .gallery img {
    min-width: calc((100vw / 3) - 2rem - 40px) !important;
    width: calc((100vh / 3) - 2rem  - 40px) !important;
    height: calc((100vh / 3) - 2rem  - 40px) !important;
    list-style: none;
    object-fit: cover;
  }

  #container .thumb a:visited,  #container .thumb a:focus,  #container .thumb a,  #container .thumb a:link {
    text-decoration: none;
    border-bottom: none;
  }

</style>