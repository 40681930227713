<template>
  <ion-app>
    <Welcome v-if="!user" />
    <Footer v-if="user" />
  </ion-app>
</template>

<script>
import { IonApp } from "@ionic/vue";
import { defineComponent } from "vue";
import Footer from "./components/Footer.vue";
import Welcome from "./views/Welcome.vue";
import { Preferences } from "@capacitor/preferences";
import { mapState } from "vuex";
import { App } from '@capacitor/app';
import { Browser } from "@capacitor/browser";


export default defineComponent({
  name: "App",
  components: {
    IonApp,
    Footer,
    Welcome,
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
  },
  async created() {
    const storedUser = await Preferences.get({ key: "user" });
    App.addListener("appUrlOpen", (data) => {
      console.log("App opened with URL: " + data.url);
      const url = new URL(data.url);
      if (data.url.indexOf("kazoulimburgapp://auth/loggedin") > -1) {
        console.log("close");
        Browser.close();
        const codeJwt = url.searchParams.get("code")
        this.$store.dispatch('setUser', JSON.parse(window.atob(codeJwt.split('.')[1])));
      }
    });

    if (storedUser && storedUser.value) {
      const user = JSON.parse(storedUser.value);
      this.$store.dispatch("setUser", user);
    }
  },
});
</script>

<style>
a {
  text-decoration: none;
}
</style>