<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar color="primary">
        <ion-title>Wiki</ion-title>
      </ion-toolbar>
      <ion-toolbar>
        <ion-searchbar placeholder="Zoeken"
          autocorrect="off"
          enterkeyhint="search"
          v-model="searchTerm"
          debounce="1000"
          @ionChange="search()" />
      </ion-toolbar>

    </ion-header>

    <ion-content :fullscreen="true">
      <router-link :to="'/wiki/Categorie:moni'">
        <ion-item detail>
          <img src="img/home/Home-moni.jpg" />
        </ion-item>
      </router-link>
      <router-link :to="'/wiki/Categorie:VV'">
        <ion-item detail>
          <img src="img/home/Home-vv.jpg" />
        </ion-item>
      </router-link>
      <router-link :to="'/wiki/Categorie:TP'">
        <ion-item detail>
          <img src="img/home/Home-tp.jpg" />
        </ion-item>
      </router-link>
      <router-link :to="'/wiki/Categorie:Verpleegkundige'">
        <ion-item detail>
          <img src="img/home/Home-vpk.jpg" />
        </ion-item>
      </router-link>
      <router-link :to="'/wiki/Categorie:Instructeur'">
        <ion-item detail>
          <img src="img/home/Home-instructeur.jpg" />
        </ion-item>
      </router-link>
      <router-link :to="'/wiki/Categorie:Zinspeler'">
        <ion-item detail>
          <img src="img/home/Home-zinspeler.jpg" />
        </ion-item>
      </router-link>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonSearchbar,
  IonItem,
} from "@ionic/vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "Home",
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonSearchbar,
    IonItem,
  },
  data() {
    return {
      searchTerm: null,
    };
  },
  methods: {
    search() {
      this.$router.push({name: 'WikiSearch', params: {search: this.searchTerm}});
      this.searchTerm = null;
    },
  },
});
</script>

<style scoped>
#container {
  text-align: center;

  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;

  color: #8c8c8c;

  margin: 0;
}

#container a {
  text-decoration: none;
}
</style>