import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import Home from '../views/Home.vue'
import User from '../views/User.vue'
import Events from '../views/Events.vue'
import View from '../views/View.vue'
import Wiki from '../views/Wiki.vue'
import WikiSearch from '../views/WikiSearch.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/wiki/search/:search',
    name: 'WikiSearch',
    component: WikiSearch
  },
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/events',
    name: 'Events',
    component: Events
  },
  {
    path: '/more',
    name: 'User',
    component: User
  },
  {
    path: '/wiki',
    name: 'Wiki',
    component: Wiki
  },
  {
    path: '/wiki/:page',
    name: 'WikiView',
    component: View
  }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: 'smooth',
      }
    }

    return { left: 0, top: 0 }
  }
})

export default router
