import { createStore } from 'vuex'
import { Preferences } from "@capacitor/preferences";

// Create a new store instance.
export const store = createStore({
    state () {
        return {
            user: 0
        }
    },
    actions: {
        async setUser ({ commit }, user) {
            await Preferences.set({ key: 'user', value: JSON.stringify(user) });
            commit('setUser', user);
        }
    },
    mutations: {
        setUser (state, user) {
            state.user = user;
        }
    }
})
