<template>
  <ion-page>

    <ion-content :fullscreen="true">
      <div class="homepage">
        <div class="welcome">
          <span>Hallo,</span><br />
          {{ user.firstname  }}
        </div>
        <div class="quicklinks">
          <router-link :to="'/wiki'" class="quicklink">
            <ion-icon size="large"  :icon="documentText"></ion-icon>
            <div>Wiki</div>
          </router-link>
          <a href="https://onkosten.kazou.be/" class="quicklink">
              <ion-icon size="large"  :icon="logoEuro"></ion-icon>
              <div>Onkosten</div>
          </a>
          <a href="https://limburg.kazou.be/" class="quicklink">
              <ion-icon size="large"  :icon="desktopOutline"></ion-icon>
              <div>Website</div>
          </a>
        </div>
        <router-link :to="'/wiki/Categorie:Het%20verbond'">
          <div class="full-page-link">
            Het verbond
          </div>
        </router-link>
        <router-link :to="'/wiki/Noodpermanentie'">
          <div class="full-page-link">
            Noodpermanentie
          </div>
        </router-link>
        <router-link :to="'/wiki/Parking%20Diepenbeek'">
          <div class="full-page-link">
            Parking Diepenbeek
          </div>
        </router-link>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonContent,
  IonIcon,
  IonPage,
} from "@ionic/vue";
import { defineComponent } from "vue";
import { mapState } from 'vuex'
import {
  calendar,
  personCircle,
  home,
  documentText,
  ellipsisHorizontal,
  desktopOutline,
  logoEuro
} from "ionicons/icons";

export default defineComponent({
  name: "Home",
  components: {
    IonContent,
    IonPage,
    IonIcon
  },
  data() {
    return {
      calendar,
      personCircle,
      home,
      documentText,
      ellipsisHorizontal,
      desktopOutline,
      logoEuro
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user
    })
  },
});
</script>

<style scoped>
#container {
  text-align: center;

  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;

  color: #8c8c8c;

  margin: 0;
}

#container a {
  text-decoration: none;
}
.welcome{
  margin: 2rem;
  font-size: 1.5rem;
  margin-top: 4rem;
}
.welcome span {
  display: inline-block;
  font-size: 1.2rem;
}


.quicklinks{
  width: calc(100% - 4 rem);
  display: flex;
  justify-content: space-between;
  margin: 2rem;
}

.quicklink{
  width: 30%;
  background: rgb(246 246 246);
  height: 150px;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.quicklink div {
  margin-top: 20px;
}

.full-page-link{
  margin: 2rem;
  width: calc(100% - 4 rem);
  padding: 1.5rem 1rem;
  background: rgb(246 246 246);
    color: black;}

</style>